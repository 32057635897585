import * as showdown from 'showdown';
import home from '../../../docs/home.md';
import sidebar from '../../../docs/sidebar.md';

export class Docs {
    private navElement: HTMLElement;
    private contentElement: HTMLElement;
    private paths: string[];
    private converter: showdown.Converter;

    constructor() {
        this.converter = new showdown.Converter({
            // enable hyperlinks without specific markdown syntax
            // note: undoubtly the question will arise to open in new tab, see for this:
            // https://github.com/showdownjs/showdown/issues/337
            simplifiedAutoLink: true,
            tables: true
        });
    }

    private async activate(params: any): Promise<void> {
        // Create a paths array up to a depth of 5, should be 'deep' enough, deepest at time of writing is 2 folders deep
        this.paths = [
            params.path1, params.path2, params.path3, params.path4, params.path5
        ].filter((path) => path); // Filter out any undefined paths

        if (this.navElement && this.contentElement)
            await this.initialiseContent();
    }

    private async attached(): Promise<void> {
        this.initialiseSidemenu();
        await this.initialiseContent();
    }

    private async initialiseSidemenu(): Promise<void> {
        const sidemenuHtml = this.converter.makeHtml(sidebar);
        this.navElement.innerHTML = sidemenuHtml;
    }

    private async initialiseContent(): Promise<void> {
        let markdownString: string;

        try {
            if (this.paths.length > 0) {
                // Import requested page by the given path
                markdownString = await import(`../../../docs/${this.paths.join('/')}`)
                    .then((m) => m.default);
            } else
                // If no path was given, default to docs home
                markdownString = home;
        } catch (error) {
            markdownString = '##### Content could not be loaded';
        }

        const contentHtml = this.converter.makeHtml(markdownString);

        this.contentElement.innerHTML = contentHtml;
    }
}
