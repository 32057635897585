import { PLATFORM } from 'aurelia-pal';
import { RouteConfig, Router, RouterConfiguration } from 'aurelia-router';

export class SettingsRouter {
    private maps(): RouteConfig[] {
        return [
            {
                route: ['', 'user-preferences'],
                href: 'settings/user-preferences',
                name: 'User Preferences',
                moduleId: PLATFORM.moduleName('./user-preferences/user-preferences'),
                nav: true,
                title: 'User Preferences',
                settings: { icon: 'fal fa-user fa-fw', breadcrumb: false, showTitle: true }
            },
            {
                route: ['notifications'],
                href: 'settings/notifications',
                name: 'Notifications',
                moduleId: PLATFORM.moduleName('./notifications/notifications'),
                nav: true,
                title: 'Notifications',
                settings: { icon: 'fal fa-bell fa-fw', breadcrumb: true, showTitle: true }
            },
            {
                route: 'edr-exclusion-list',
                href: 'settings/edr-exclusion-list',
                name: 'EDR Exclusion List',
                moduleId: PLATFORM.moduleName('./edr-exclusion-list/edr-exclusion-list'),
                nav: true,
                title: 'EDR Exclusion',
                settings: { icon: 'fal fa-bookmark fa-fw', breadcrumb: true, isEdr: true, showTitle: true }
            },
            {
                route: 'utm-configuration',
                href: 'settings/utm-configuration',
                name: 'UTM Configuration',
                moduleId: PLATFORM.moduleName('./utm-configuration/utm-configuration'),
                nav: true,
                title: 'UTM Configuration',
                settings: { icon: 'fa-kit fa-light-shield-gear fa-fw', breadcrumb: true, isUtm: true, showTitle: true }
            },

            // Include the not-found route in the routes, so we can also manually navigate to it
            this.unknownRoute,
        ] as RouteConfig[];
    }

    private unknownRoute: RouteConfig = {
        route: 'not-found',
        name: 'unknown',
        title: 'Not Found',
        moduleId: PLATFORM.moduleName('views/not-found/not-found'),
        settings: { allowAnonymous: true }
    };

    private configureRouter(config: RouterConfiguration, router: Router): void {
        config.options.pushState = true;
        config.map(this.maps());
        config.mapUnknownRoutes(this.unknownRoute);
    }
}
