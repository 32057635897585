import { autoinject, containerless } from 'aurelia-framework';
import {
    NotificationConfiguration,
    NotificationConfigurationApiClient, NotificationDeliveryTarget,
    Rule,
    RuleName,
    ThreatsApiClient
} from 'services/cyber-api';
import { StateApi } from 'services/state-api';
import Swal from 'sweetalert2';
import { Toast } from 'utilities/toast';

@autoinject()
@containerless()
export class NotificationSettings {
    private email: string;
    private emailInvalid: boolean = false;
    private notificationConfiguration: NotificationConfiguration;
    private threatCategories: string[] = [];
    private loadingRules: boolean = true;
    private loadingRuleTemplates: boolean = true;
    private loadingAction: boolean = false;
    private loadingEmail: boolean = false;

    private ruleTemplates: RuleTemplateCustom[];
    private newRule: INewRule = {
        ruleName: undefined,
        configuration: []
    };
    private RuleName: typeof RuleName = RuleName;
    private enableNewRule = true;

    constructor(
        private notificationConfigurationApi: NotificationConfigurationApiClient,
        private threatsApi: ThreatsApiClient,
        private state: StateApi
    ) {
    }

    private async attached(): Promise<void> {
        // Retrieve all data in parallel
        await Promise.all([
            this.retrieveDeliveryRules(),
            this.retrieveThreatCategories(),
            this.retrieveRuleTemplates()
        ]).then(() => {
            this.updateRuleTemplatesEnabledState();
        });
    }

    private async updateRuleConfiguration(rule: EnrichedRule, selectedValues: string[]): Promise<void> {
        this.loadingAction = true;
        rule.configuration = selectedValues;
        if (rule.configuration?.length === 0 || rule.configuration === null) {
            await this.removeNotificationDeliveryRule(rule);
            this.loadingAction = false;
            return;
        }
        let ruleResult = await this.notificationConfigurationApi.updateRule(this.state.company(), rule);
        rule.configuration = ruleResult.configuration;
        rule.title = this.getDeliveryRuleTitle(rule, rule.configuration);

        this.loadingAction = false;
    }

    private async updateRuleTemplatesEnabledState(): Promise<void> {
        this.loadingRuleTemplates = true;
        // determine if the rules should be enabled based on the existence of a delivery rule
        this.ruleTemplates.forEach((rt) => {
            const rule = this.notificationConfiguration.rules.find((r) => r.ruleName === rt.ruleName);
            rt.enabled = !rule;
        });

        // Set NewActiveThreatDetected as first item #45420, only if enabled
        const found = this.ruleTemplates.find(el => el.ruleName === RuleName.NewActiveThreatDetected);
        const foundIndex = this.ruleTemplates.findIndex(el => el.ruleName === RuleName.NewActiveThreatDetected);
        if (found.enabled) {
            this.ruleTemplates.splice(foundIndex, 1);
            this.ruleTemplates.unshift(found);
        } else {
            const foundEnabled = this.ruleTemplates.find(el => el.enabled === true);
            const foundIndexEnabled = this.ruleTemplates.findIndex(el => el.enabled === true);
            if (foundEnabled) {
                this.ruleTemplates.splice(foundIndexEnabled, 1);
                this.ruleTemplates.unshift(foundEnabled);
            }
        }


        this.enableNewRule = this.ruleTemplates[0].enabled;
        if (this.enableNewRule)
            this.newRule.ruleName = this.ruleTemplates[0].ruleName;
        this.loadingRuleTemplates = false;
    }

    private async retrieveDeliveryRules(): Promise<void> {
        this.notificationConfiguration = await this.notificationConfigurationApi.getByUserId(this.state.company());
        this.email = this.notificationConfiguration.email;
        this.notificationConfiguration.rules = this.notificationConfiguration.rules.map((rule) => {
            let enrichedRule = rule as EnrichedRule;
            enrichedRule.title = this.getDeliveryRuleTitle(enrichedRule, enrichedRule.configuration);
            return enrichedRule;
        });
        this.notificationConfiguration.rules = this.notificationConfiguration.rules.sort((a, b) => a.ruleName < b.ruleName ? -1 : 1);
        this.loadingRules = false;
    }

    private async retrieveThreatCategories(): Promise<void> {
        const threatTypes = await this.threatsApi.getAllThreatTypes(this.state.company());
        this.threatCategories = [...new Set(threatTypes.map((t) => t.category))] // Map to unique categories
            .sort((a, b) => a < b ? -1 : 1); // Sort alphabetically
    }

    private async retrieveRuleTemplates(): Promise<void> {
        let ruleTemplates: RuleTemplateCustom[] = [];
        const keys = Object.keys(RuleName)
        keys.forEach((key) => {

            ruleTemplates.push({
                ruleName: RuleName[key],
                title: this.getDeliveryRuleTitle(new EnrichedRule({ ruleName: RuleName[key] as RuleName }), []),
                enabled: true
            })
        })

        // Sort rule templates alphabetically
        this.ruleTemplates = ruleTemplates.sort((a, b) => a.title < b.title ? -1 : 1).map((rt) => ({
            ...rt
        }));
    }

    /**
     * Updates the enabled state of a notification delivery rule, enabling or disabling them.
     * @param notificationRule A notification delivery rule to enable or disable
     * @param enabled The enabled state to set for the delivery rule (true/false : enabled/disabled)
     */
    private async updateEnabledState(notificationRule: Rule, enabled: boolean): Promise<void> {
        this.loadingAction = true;

        if (enabled)
            notificationRule = await this.notificationConfigurationApi.enable(notificationRule.ruleName, this.state.company());
        else
            notificationRule = await this.notificationConfigurationApi.disable(notificationRule.ruleName, this.state.company());

        this.loadingAction = false;
    }

    /**
     * Updates the enabled state of a delivery target of a notification delivery rule.
     * @param notificationRule A notification delivery rule to update a delivery target of
     * @param target The delivery target to update
     * @param enabled Whether the delivery target should be enabled or disabled
     */
    private async updateDeliveryTarget(notificationRule: Rule, target: NotificationDeliveryTarget, enabled: boolean): Promise<void> {
        this.loadingAction = true;

        if (enabled)
            notificationRule = await this.notificationConfigurationApi.enableDeliveryTarget(notificationRule.ruleName, target, this.state.company());
        else
            notificationRule = await this.notificationConfigurationApi.disableDeliveryTarget(notificationRule.ruleName, target, this.state.company());

        this.loadingAction = false;
    }

    /**
     * Updates a threshold rule with a new threshold value.
     * @param notificationDeliveryRule Notification delivery rule the threshold rule is contained in
     * @param values
     */
    private async updateThresholdRule(notificationDeliveryRule: EnrichedRule, values: string[]): Promise<void> {
        this.loadingAction = true;


        notificationDeliveryRule.title = this.getDeliveryRuleTitle(notificationDeliveryRule, values);

        // Finally, update the notification delivery rule in the API
        let ruleResult = await this.notificationConfigurationApi.updateRule(this.state.company(), notificationDeliveryRule);
        notificationDeliveryRule.configuration = ruleResult.configuration;

        this.loadingAction = false;
    }

    /**
     * Updates the notification delivery rule email targets to the given email value.
     * @param email Email to set as value for the email targets
     */
    private async updateEmail(email: string): Promise<void> {
        // Validate the email
        this.emailInvalid = email ? !email.trim() : true;
        if (this.emailInvalid) return;

        this.loadingEmail = true;

        // Update the email in the API
        await this.notificationConfigurationApi.updateUsernameAndEmailValue(email, this.state.company());

        this.loadingEmail = false;
    }

    private async addNotificationDeliveryRule(newRule: INewRule): Promise<void> {
        this.loadingAction = true;
        if (newRule.ruleName === undefined) {
            Toast.warning('Please select a rule');
            return;
        }

        let rule = new Rule({
            ruleName: newRule.ruleName,
            configuration: newRule.configuration,
            isNotificationEnabled: true,
            isEmailEnabled: !!this.notificationConfiguration.email,
            isWebEnabled: true
        });
        let notificationDeliveryRule = await this.notificationConfigurationApi.create(this.state.company(), rule) as EnrichedRule;

        notificationDeliveryRule.title = this.getDeliveryRuleTitle(notificationDeliveryRule, notificationDeliveryRule.configuration);
        this.notificationConfiguration.rules.push(notificationDeliveryRule);

        await this.updateRuleTemplatesEnabledState();

        this.newRule = {
            ruleName: undefined,
            configuration: []
        };


        this.loadingAction = false;
    }

    private async removeNotificationDeliveryRule(notificationDeliveryRule: Rule): Promise<void> {
        Swal.fire({
            title: 'Remove notification rule',
            html: `Are you sure you want to remove this notification rule?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, cancel',
            confirmButtonText: 'Yes, remove rule',
        }).then(async (result) => {
            if (result.value) {
                this.loadingAction = true;
                this.notificationConfigurationApi.delete(notificationDeliveryRule.ruleName, this.state.company())
                    .then(async () => {
                        Toast.success('Notification rule has been removed');
                        await this.retrieveDeliveryRules();

                        await this.updateRuleTemplatesEnabledState();
                        this.loadingAction = false;
                    })
                    .catch(() =>
                        Toast.warning('Removing the notification rule was unsuccessful, please try again later')
                    );
            }
        });
    }

    private getDeliveryRuleTitle(notificationDeliveryRule: EnrichedRule, value: string[]): string {
        switch (notificationDeliveryRule.ruleName) {
            case RuleName.NewComment:
                return `New comment`;
            case RuleName.VesselRisk:
                return `Vessel risk increased to `;
            case RuleName.NewCaseCreated:
                return `New case created`;
            case RuleName.AnyNewThreatDetected:
                return `Any new threat detected (not recommended)`;
            case RuleName.NewCaseCommentCreated:
                return `New case comment created`;
            case RuleName.NewActiveThreatDetected:
                return `New active threat detected`;
            case RuleName.IncreaseInThreatsByHours:
                return `Increase in threats in the last 6 hours`;
            case RuleName.InvestigationStatusChange:
                return `Investigation status changed to `;
            case RuleName.NewThreatDetectedInCategory:
                return `New threat detected in category`;
            case RuleName.NewThreatDetectedWithUrgency:
                return `New threat detected with urgency of `;
            case RuleName.AnyEdrAlertDetected:
                return `Any EDR alert detected (not recommended)`;
        }
    }
}

interface INewRule {
    ruleName: RuleName;
    configuration: string[];
}

class RuleTemplateCustom {
    ruleName: RuleName;
    title: string;
    enabled: boolean;
}

class EnrichedRule extends Rule {
    title: string;
}
