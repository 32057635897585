import { autoinject } from 'aurelia-framework';
import { RedirectToRoute } from 'aurelia-router';
import { AuthenticationProvider } from 'providers/authentication-provider';
import { CompaniesApiClient, CompanyLightweight } from 'services/cyber-api';
import { StateApi, StateKeys } from 'services/state-api';
import { NotificationReceiver } from './../../components/notification-receiver/notification-receiver';

@autoinject
export class Switch {
    private companies: CompanyLightweight[];
    private selectedCompanyId: string;

    constructor(
        private state: StateApi,
        private auth: AuthenticationProvider,
        private notifications: NotificationReceiver,
        private companiesApi: CompaniesApiClient
    ) { }

    private async canActivate(): Promise<RedirectToRoute> {
        const response = await this.companiesApi.getAll(undefined);
        this.companies = response.sort((a, b) => {
            return (a.name.toUpperCase() < b.name.toUpperCase()) ? -1 : 1;
        });

        // Users may only switch company if there are multiple companies available to the user
        if (!this.companies || this.companies.length < 2)
            return new RedirectToRoute('unknown', {}, { replace: true });
    }

    private async confirm(): Promise<void> {
        try {
            // Attempt to unsubscribe from company-specific notifications
            await this.notifications.unsubscribe();
        } catch (error) {
            console.warn(error);
        }

        const selectedCompany = this.companies.find((c) => c.id.toUpperCase() === this.selectedCompanyId.toUpperCase());

        this.state.setState(StateKeys.Company, selectedCompany.id);
        this.state.setState(StateKeys.CompanyName, selectedCompany.name);

        // Logout first, which includes clearing the localStorage, then immediately set the state
        this.auth.logout();
    }
}
