import { autoinject } from 'aurelia-framework';
import { EventKeys } from '../../../enums/event-keys';
import { EventAggregator } from 'aurelia-event-aggregator';
import moment from 'moment-timezone';
import { UserProfileApiClient } from '../../../services/cyber-api';
import { StateApi } from '../../../services/state-api';

@autoinject()
export class UserPreferences {
    private date = moment();
    private localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    private useUtc: boolean;
    private loading: boolean = true;
    private selectedValue: boolean;

    constructor(
        private eventAggregator: EventAggregator,
        private userProfileApi: UserProfileApiClient,
        private state: StateApi,
    ) {
    }

    private get selectedValueInView() {
        return this.selectedValue;
    }

    private set selectedValueInView(val) {
        this.selectedValue = val;
        this.updateTimezone();
    }

    private async attached(): Promise<void> {
        const userProfileSettings = await this.userProfileApi.getSettings(this.state.company());
        this.useUtc = userProfileSettings.timeZone === 'UTC';
        this.selectedValue = this.useUtc;

        // update date to reflect new timezone
        this.date = moment();

        this.loading = false;
    }

    private updateTimezone(): void {
        let timezone = this.selectedValue ? 'UTC' : this.localTimezone;

        // publish event to notify other components of timezone change
        this.eventAggregator.publish(EventKeys.onTimeZoneChanged, timezone);

        // update date to reflect new timezone
        // we need to specify the timezone because we don't know when the event publish will be done
        this.date = moment.tz(timezone);
    }
}
